import { Territories } from '@amzn/sitc-frontend-types/stap/territories';
import { Territory } from '@amzn/studios-domain-authority-java-script-clients/types/stap';
import { sanitizeMaybeArray } from '@amzn/studios-domain-authority-java-script-clients/util';
import React from 'react';

import { BasicTypeRevision } from './BasicTypeRevision';

interface Props {
  territories?: Territory[];
}

export const TerritoryRevision: React.FC<Props> = ({ territories = [] }) => (
  <BasicTypeRevision
    types={Territories}
    value={sanitizeMaybeArray<Territory>(territories).map((territory: Territory) => territory.value || '')}
  />
);
