import { Action } from '@amzn/studios-origin-edit-app-auth/constants';
import _noop from 'lodash/noop';
import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { baseRoute, supportRequestLink, updateRoute, viewRoute } from '../configs/navigation';
import { useFormMode } from '../hooks/form-mode';
import { useEditsNavigationParams } from '../hooks/navigation-params';
import { usePermission } from './Authorization';

export interface NavigationProviderProps {
  children: React.ReactNode;
}

export interface NavigationContextState {
  navigateToRoot: () => void;
  navigateToTitleView: (titleDocumentId?: string) => void;
  navigateToFeatureUpdate: () => void;
  navigateToRequestUpdate: () => void;
  navigateToSeasonUpdate: (seasonDocumentId: string) => void;
  openSupportTicket: () => void;
  onSeasonUpdatePage: boolean;
}

export const defaultNavigationContextState: NavigationContextState = {
  navigateToRoot: _noop,
  navigateToTitleView: _noop,
  navigateToFeatureUpdate: _noop,
  navigateToRequestUpdate: _noop,
  navigateToSeasonUpdate: _noop,
  openSupportTicket: _noop,
  onSeasonUpdatePage: false,
};

export const NavigationContext = React.createContext<NavigationContextState>(defaultNavigationContextState);

export const NavigationProvider: React.FC<NavigationProviderProps> = ({ children }) => {
  const { isEditing } = useFormMode();
  const hasEditPermission = usePermission(Action.Edit);
  const { currentTitleDocumentId = '', currentSeasonDocumentId = '' } = useEditsNavigationParams();
  const navigate = useNavigate();

  const openSupportTicket = () => window.open(supportRequestLink, '_blank', 'noopener noreferrer');

  const navigateToRequestUpdate = (): void => {
    if (hasEditPermission) {
      const handle = window.open(`/origin-author/requests/titles/${currentTitleDocumentId}/update`, '_self');
      if (handle) {
        handle.focus();
      }
    } else {
      openSupportTicket();
    }
  };

  const value = useMemo(
    () => ({
      navigateToRoot: () => navigate(`/${baseRoute}`),
      navigateToTitleView: (titleDocumentId?: string) =>
        navigate(`/${baseRoute}/${titleDocumentId || currentTitleDocumentId}/${viewRoute}`),
      navigateToFeatureUpdate: () => navigate(`/${baseRoute}/${currentTitleDocumentId}/${updateRoute}`),
      navigateToRequestUpdate,
      navigateToSeasonUpdate: (seasonDocumentId: string) =>
        navigate(`/${baseRoute}/${currentTitleDocumentId}/seasons/${seasonDocumentId}/${updateRoute}`),
      openSupportTicket,
      onSeasonUpdatePage: !!(currentSeasonDocumentId && isEditing),
    }),
    [isEditing, currentTitleDocumentId, currentSeasonDocumentId]
  );

  return <NavigationContext.Provider value={value}>{children}</NavigationContext.Provider>;
};
