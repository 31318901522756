import { compareUnorderedArrayObjects } from '@amzn/sitc-frontend/utils/revision-history';
import { Attribute } from '@amzn/sitc-frontend-types/revision-history';
import { EditDeliveryStatusTypes } from '@amzn/sitc-frontend-types/stap/edit-delivery-status';
import { EditOutcomeTypes } from '@amzn/sitc-frontend-types/stap/edit-outcome';
import { EditRequestingTeamTypes } from '@amzn/sitc-frontend-types/stap/edit-requesting-team';
import { FinalEditClassTypes } from '@amzn/sitc-frontend-types/stap/final-edit-class';
import { MadeForMediaTypes } from '@amzn/sitc-frontend-types/stap/made-for-media';
import { TreatmentTypes } from '@amzn/sitc-frontend-types/stap/treatment';
import React from 'react';
import { TFunction } from 'react-i18next';

import { BasicTypeRevision } from '../../components/RevisionHistory/renderers/BasicTypeRevision';
import { NotesRevision } from '../../components/RevisionHistory/renderers/NotesRevision';
import { TerritoryRevision } from '../../components/RevisionHistory/renderers/TerritoryRevision';
import { EditTarget } from '../../components/RevisionHistory/targets/EditTarget';
import { EditsFieldKey } from '../../types/edits-form';

const getAttributeLabelKey = (fieldKey: EditsFieldKey): string => `app.activity_feed.field.${fieldKey}.label`;

export const getIsdgAttributes = (translate: TFunction<'translation', undefined>): Record<string, Attribute> => ({
  // Edit
  alternateEditName: {
    label: translate(getAttributeLabelKey(EditsFieldKey.AlternateEditName)),
    target: EditTarget,
  },
  deliveryStatus: {
    label: translate(getAttributeLabelKey(EditsFieldKey.EditDeliveryStatus)),
    render: ({ value }) => <BasicTypeRevision types={EditDeliveryStatusTypes} value={value} />,
    target: EditTarget,
  },
  editNotes: {
    label: translate(getAttributeLabelKey(EditsFieldKey.Notes)),
    compare: compareUnorderedArrayObjects,
    render: ({ value }) => <NotesRevision notes={value} />,
    target: EditTarget,
  },
  finalEditClasses: {
    label: translate(getAttributeLabelKey(EditsFieldKey.FinalEditClass)),
    render: ({ value }) => <BasicTypeRevision types={FinalEditClassTypes} value={value} />,
    target: EditTarget,
  },
  madeForMedia: {
    label: translate(getAttributeLabelKey(EditsFieldKey.MadeForMedia)),
    render: ({ value }) => <BasicTypeRevision types={MadeForMediaTypes} value={value} />,
    target: EditTarget,
  },

  // EditDecision
  applicableTerritories: {
    label: translate(getAttributeLabelKey(EditsFieldKey.Territory)),
    render: ({ value }) => <TerritoryRevision territories={value} />,
    target: EditTarget,
  },
  editDecisionNotes: {
    label: translate(getAttributeLabelKey(EditsFieldKey.Notes)),
    target: EditTarget,
  },
  recommendedOutcome: {
    label: translate(getAttributeLabelKey(EditsFieldKey.RecommendedOutcome)),
    render: ({ value }) => <BasicTypeRevision types={EditOutcomeTypes} value={value} />,
    target: EditTarget,
  },
  treatments: {
    label: translate(getAttributeLabelKey(EditsFieldKey.Treatment)),
    render: ({ value }) => <BasicTypeRevision types={TreatmentTypes} value={value} />,
    target: EditTarget,
  },

  // DecisionOwner
  requestingTeam: {
    label: translate(getAttributeLabelKey(EditsFieldKey.DecisionOwner)),
    render: ({ value }) => <BasicTypeRevision types={EditRequestingTeamTypes} value={value} />,
    target: EditTarget,
  },
});
