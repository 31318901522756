import { DecisionOwner, Edit, EditDecision } from '@amzn/studios-domain-authority-java-script-clients/types/stap';

export type IsdgRevisionImage = Pick<Edit, 'alternateEditName' | 'deliveryStatus' | 'editNotes' | 'finalEditClasses' | 'madeForMedia'> &
  Pick<EditDecision, 'applicableTerritories' | 'decisionType' | 'editDecisionNotes' | 'recommendedOutcome' | 'treatments'> &
  Pick<DecisionOwner, 'requestingTeam'>;

export enum EventNames {
  Activate = 'activate',
  Register = 'register',
  Registered = 'registered',
}

export type RevisionHistoryRegisteredEventPayload = {
  registrationKey: string;
  errors?: Error[];
  success: boolean;
};
