import { useContext } from 'react';

import { RevisionHistoryContext, RevisionHistoryContextValue } from '../context/RevisionHistory';

export const useRevisionHistory = (): RevisionHistoryContextValue => {
  const revisionHistory = useContext(RevisionHistoryContext);

  if (!revisionHistory) {
    throw Error('Missing RevisionHistoryProvider');
  }

  return revisionHistory;
};
