import { MaybeNull } from '@amzn/sitc-frontend-types/helper';
import { Maybe } from '@amzn/studios-domain-authority-java-script-clients/types/stap';

import { EditStatusOption } from '../utils/edit-delivery-status';
import { EditDetailOption } from '../utils/edit-details';
import { TerritoryOption } from '../utils/territories';

export enum EditsFieldKey {
  EditVertexId = 'editVertexId',
  EditDecisionVertexId = 'editDecisionVertexId',
  DecisionOwnerVertexId = 'decisionOwnerVertexId',
  AdditionalEdits = 'additionalEdits',
  AlternateEditName = 'alternateEditName',
  DecisionOwner = 'decisionOwner',
  EditDetails = 'editDetails',
  EditDeliveryStatus = 'editDeliveryStatus',
  Episodes = 'episodes',
  FinalEditClass = 'finalEditClass',
  MadeForMedia = 'madeForMedia',
  Notes = 'notes',
  OriginalEdit = 'originalEdit',
  OriginalEditStatus = 'originalEditStatus',
  RecommendedOutcome = 'recommendedOutcome',
  Territory = 'territory',
  Treatment = 'treatment',
}

export interface OriginalEditFormValues {
  [EditsFieldKey.EditVertexId]: string;
  [EditsFieldKey.EditDecisionVertexId]: string;
  [EditsFieldKey.AlternateEditName]: string;
  [EditsFieldKey.MadeForMedia]: string;
  [EditsFieldKey.Notes]: string;
  [EditsFieldKey.OriginalEditStatus]: string;
  [EditsFieldKey.RecommendedOutcome]: Maybe<string>;
}

export interface AdditionalEditFormValues {
  [EditsFieldKey.EditVertexId]: string;
  [EditsFieldKey.EditDecisionVertexId]: string;
  [EditsFieldKey.DecisionOwnerVertexId]: string;
  [EditsFieldKey.AlternateEditName]: string;
  [EditsFieldKey.DecisionOwner]: string;
  [EditsFieldKey.EditDetails]: EditDetailOption[];
  [EditsFieldKey.EditDeliveryStatus]: Maybe<EditStatusOption>;
  [EditsFieldKey.MadeForMedia]: string;
  [EditsFieldKey.Notes]: string;
  [EditsFieldKey.Territory]: TerritoryOption[];
}

export interface FeatureFormValues {
  [EditsFieldKey.OriginalEdit]: OriginalEditFormValues;
  [EditsFieldKey.AdditionalEdits]: AdditionalEditFormValues[];
}

export interface SeasonFormValues {
  vertexId: string;
  episodes: {
    vertexId: string;
    [EditsFieldKey.OriginalEdit]: OriginalEditFormValues;
    [EditsFieldKey.AdditionalEdits]: AdditionalEditFormValues[];
  }[];
}

export type FormValues = FeatureFormValues | SeasonFormValues;

export type CommonFieldProps = {
  disableFloatingLabel?: boolean;
  fieldKey?: string;
};

export type AutocompleteOnChange<T> = (event: React.SyntheticEvent, value: MaybeNull<T>) => void;
