import { Territories, TerritoryCode } from '@amzn/sitc-frontend-types/stap/territories';

export enum TerritoryGroup {
  SpecialComplianceTerritories = 'SpecialComplianceTerritories',
  SpecialRatingsTerritories = 'SpecialRatingsTerritories',
  Other = 'Other',
}

export interface TerritoryOption {
  value: string;
  label: string;
  group: TerritoryGroup;
}

const createSpecialTerritoryOptions = (territoryCodes: TerritoryCode[], territoryGroup: TerritoryGroup): TerritoryOption[] =>
  territoryCodes.map((code: TerritoryCode) => ({
    value: code,
    label: Territories[code].label,
    group: territoryGroup,
  }));

export const territoryOptions: TerritoryOption[] = [
  ...createSpecialTerritoryOptions(
    [TerritoryCode.Eg, TerritoryCode.In, TerritoryCode.Sa, TerritoryCode.Tr, TerritoryCode.Ae],
    TerritoryGroup.SpecialComplianceTerritories
  ),
  ...createSpecialTerritoryOptions(
    [
      TerritoryCode.Au,
      TerritoryCode.Br,
      TerritoryCode.De,
      TerritoryCode.In,
      TerritoryCode.Id,
      TerritoryCode.Nz,
      TerritoryCode.Sg,
      TerritoryCode.Za,
    ],
    TerritoryGroup.SpecialRatingsTerritories
  ),
  ...Object.entries(Territories).map(([territoryCode, territory]) => ({
    value: territoryCode,
    label: territory.label,
    group: TerritoryGroup.Other,
  })),
];

export const formatTerritoryOption = ({ label, value }: TerritoryOption): string => `${label} - ${value}`;
