import { CommonUserPreferences } from '@amzn/sitc-frontend/types';
import noop from 'lodash/noop';
import React, { useMemo, useState } from 'react';

interface ThemeModeProviderProps {
  children: React.ReactNode;
  themePreference: CommonUserPreferences['theme'];
}

export const ThemeModeContext = React.createContext<{
  themeMode: string;
  setThemeMode: (themeMode: string) => void;
}>({
  themeMode: '',
  setThemeMode: noop,
});

export const ThemeModeProvider: React.FC<ThemeModeProviderProps> = ({ children, themePreference }) => {
  const [themeMode, setThemeMode] = useState<string>(themePreference.name);

  const contextValue = useMemo(() => ({ themeMode, setThemeMode }), [themeMode]);

  return <ThemeModeContext.Provider value={contextValue}>{children}</ThemeModeContext.Provider>;
};
