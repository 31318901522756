import { StandaloneConfiguration } from '@amzn/sitc-frontend/components';
import { MicroAppRegistryInfo } from '@amzn/sitc-frontend-micro-app-sdk-core';
import React from 'react';
import ReactDOMClient from 'react-dom/client';
import singleSpaReact from 'single-spa-react';

import { ErrorBoundary } from './components/common/ErrorBoundary';
import StudiosOriginEditApp from './components/StudiosOriginEditApp';
import { microAppName } from './configs/app';

const configuration = {};

const radioConfig = { channelNames: ['app', 'origin-search', 'revision-history'] };

const microApps: MicroAppRegistryInfo[] = [
  {
    microAppName: 'amzn-studios-origin-search-app',
    packageName: '@amzn/studios-origin-search-app',
    version: '*',
    accessLevel: 'restricted',
  },
  {
    microAppName: 'amzn-studios-revision-history-app',
    packageName: '@amzn/studios-revision-history-app',
    version: '*',
    accessLevel: 'restricted',
  },
];

const rootComponent = process.env.STANDALONE
  ? () => (
      <StandaloneConfiguration
        RootComponent={StudiosOriginEditApp}
        configuration={configuration}
        microAppName={microAppName}
        microApps={microApps}
        radioConfig={radioConfig}
      />
    )
  : StudiosOriginEditApp;

const lifecycles = singleSpaReact({
  React,
  ReactDOMClient,
  rootComponent,
  errorBoundary: (error: Error) => <ErrorBoundary error={error} />,
});

export const { bootstrap, mount, unmount } = lifecycles;
