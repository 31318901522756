import noop from 'lodash/noop';
import React, { useMemo, useState } from 'react';

export interface RevisionHistoryProviderProps {
  children: React.ReactNode;
}

export interface RevisionHistoryContextValue {
  revisionHistoryReady: boolean;
  revisionHistoryRegistrationErrors: Error[];

  setRevisionHistoryReady: React.Dispatch<React.SetStateAction<boolean>>;
  setRevisionHistoryRegistrationErrors: React.Dispatch<React.SetStateAction<Error[]>>;
}

export const defaultRevisionHistoryContextState: RevisionHistoryContextValue = {
  revisionHistoryReady: false,
  revisionHistoryRegistrationErrors: [],

  setRevisionHistoryReady: noop,
  setRevisionHistoryRegistrationErrors: noop,
};

export const RevisionHistoryContext = React.createContext<RevisionHistoryContextValue>(defaultRevisionHistoryContextState);

export const RevisionHistoryProvider: React.FC<RevisionHistoryProviderProps> = ({ children }) => {
  const [revisionHistoryReady, setRevisionHistoryReady] = useState(false);
  const [revisionHistoryRegistrationErrors, setRevisionHistoryRegistrationErrors] = useState<Error[]>([]);

  const value: RevisionHistoryContextValue = useMemo(
    () => ({
      revisionHistoryReady,
      revisionHistoryRegistrationErrors,
      setRevisionHistoryReady,
      setRevisionHistoryRegistrationErrors,
    }),
    [revisionHistoryReady, revisionHistoryRegistrationErrors]
  );

  return <RevisionHistoryContext.Provider value={value}>{children}</RevisionHistoryContext.Provider>;
};
