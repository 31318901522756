import { useContext } from 'react';

import { NavigationParamsContext, NavigationParamsContextState } from '../context/NavigationParams';

export const useEditsNavigationParams = (): NavigationParamsContextState => {
  const navigationParamsContextState = useContext(NavigationParamsContext);

  if (!navigationParamsContextState) {
    throw new Error('Missing Router');
  }

  return navigationParamsContextState;
};
