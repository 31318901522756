import { useContext } from 'react';

import { FormModeContext, FormModeContextState } from '../context/FormMode';

export const useFormMode = (): FormModeContextState => {
  const formModeState = useContext(FormModeContext);

  if (!formModeState) {
    throw new Error('Missing FormModeProvider');
  }

  return formModeState;
};
