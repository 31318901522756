/* eslint-disable no-console */

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type LoggingFunction = (message?: any, ...optionalParams: any[]) => void;

// We can change console to some other library
export const logInfo: LoggingFunction = (...args) => {
  console.log(...args);
};

export const logError: LoggingFunction = (...args) => {
  console.error(...args);
};
/* eslint-enable no-console */
