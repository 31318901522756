import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';

export interface NavigationParamsProviderProps {
  children: React.ReactNode;
}

export interface NavigationParamsContextState {
  currentTitleDocumentId: string;
  currentSeasonDocumentId: string;
}

export const defaultNavigationParamsContextState: NavigationParamsContextState = {
  currentTitleDocumentId: '',
  currentSeasonDocumentId: '',
};

export const NavigationParamsContext = React.createContext<NavigationParamsContextState>(defaultNavigationParamsContextState);

export const NavigationParamsProvider: React.FC<NavigationParamsProviderProps> = ({ children }) => {
  const { titleDocumentId: currentTitleDocumentId = '', seasonDocumentId: currentSeasonDocumentId = '' } = useParams();

  const value = useMemo(
    () => ({
      currentSeasonDocumentId,
      currentTitleDocumentId,
    }),
    [currentTitleDocumentId, currentSeasonDocumentId]
  );

  return <NavigationParamsContext.Provider value={value}>{children}</NavigationParamsContext.Provider>;
};
