import React, { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { updateRoute, viewRoute } from '../configs/navigation';

export enum FormMode {
  Searching = 'Searching',
  Update = 'Update',
  View = 'View',
}

export interface FormModeProviderProps {
  children: React.ReactNode;
}

export interface FormModeContextState {
  formMode: FormMode;
  isEditing: boolean;
  isSearching: boolean;
  isViewing: boolean;
}

export const defaultFormModeContextState: FormModeContextState = {
  formMode: FormMode.Searching,
  isEditing: false,
  isSearching: true,
  isViewing: false,
};

export const FormModeContext = React.createContext<FormModeContextState>(defaultFormModeContextState);

export const FormModeProvider: React.FC<FormModeProviderProps> = ({ children }) => {
  const { pathname = '' } = useLocation();

  let formMode = FormMode.Searching;

  if (pathname.endsWith(updateRoute)) {
    formMode = FormMode.Update;
  } else if (pathname.endsWith(viewRoute)) {
    formMode = FormMode.View;
  }

  const value = useMemo(
    () => ({
      formMode,
      isEditing: formMode === FormMode.Update,
      isSearching: formMode === FormMode.Searching,
      isViewing: formMode === FormMode.View,
    }),
    [pathname]
  );

  return <FormModeContext.Provider value={value}>{children}</FormModeContext.Provider>;
};
