import { EditDeliveryStatusTypes } from '@amzn/sitc-frontend-types/stap/edit-delivery-status';
import { EditOutcomeTypes } from '@amzn/sitc-frontend-types/stap/edit-outcome';
import { BasicType } from '@amzn/sitc-frontend-types/stap/types';
import {
  EditDeliveryStatus as EditDeliveryStatusValue,
  EditOutcome as EditOutcomeValue,
} from '@amzn/studios-domain-authority-java-script-clients/types/stap';
import _omit from 'lodash/omit';
import _pick from 'lodash/pick';

export enum EditStatusGroup {
  EditDeliveryStatus = 'EditDeliveryStatus',
  EditOutcome = 'EditOutcome',
}

export interface EditStatusOption {
  group: EditStatusGroup;
  label: string;
  value: EditDeliveryStatusValue | EditOutcomeValue;
}

export const placeholderStatusOption: EditStatusOption = {
  group: EditStatusGroup.EditDeliveryStatus,
  label: EditDeliveryStatusTypes[EditDeliveryStatusValue.Pending].label,
  value: EditDeliveryStatusValue.Pending,
};

export const editDeliveryStatusOptions: EditStatusOption[] = [
  ...Object.values(_omit(EditDeliveryStatusTypes, EditDeliveryStatusValue.Invalid)).map(
    ({ label, value }: BasicType<EditDeliveryStatusValue>) => ({
      group: EditStatusGroup.EditDeliveryStatus,
      label,
      value,
    })
  ),
];

export const editOutcomeStatusOptions: EditStatusOption[] = [
  ...Object.values(_pick(EditOutcomeTypes, EditOutcomeValue.Suppress, EditOutcomeValue.RevertSuppress)).map(
    ({ label, value }: BasicType<EditOutcomeValue>) => ({
      group: EditStatusGroup.EditOutcome,
      label,
      value,
    })
  ),
];

export const allEditStatusOptions: EditStatusOption[] = [
  ...editDeliveryStatusOptions,
  ...Object.values(_pick(EditOutcomeTypes, EditOutcomeValue.Suppress)).map(({ label, value }: BasicType<EditOutcomeValue>) => ({
    group: EditStatusGroup.EditOutcome,
    label,
    value,
  })),
];
