import { MicroAppInitializationProps } from '@amzn/sitc-frontend/types';
import { useContext } from 'react';

import { MicroAppInitializationContext } from '../context/MicroAppInitialization';

export const useMicroAppInitialization = (): MicroAppInitializationProps => {
  const microAppInitializationProps = useContext(MicroAppInitializationContext);

  if (!microAppInitializationProps) {
    throw new Error('Missing MicroAppInitializationProvider');
  }

  return microAppInitializationProps;
};
