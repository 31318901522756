import { en as sitcEn } from '@amzn/sitc-frontend/lang';
import {
  EditDeliveryStatus,
  EditOutcome,
  EditRequestingTeam,
  FinalEditClass,
} from '@amzn/studios-domain-authority-java-script-clients/types/stap';

import { EditsFieldKey } from '../types/edits-form';
import { EditStatusGroup } from '../utils/edit-delivery-status';
import { EditDetailGroup } from '../utils/edit-details';
import { TerritoryGroup } from '../utils/territories';

export const en = {
  ...sitcEn,
  'app.alert.movie_not_greenlit': 'The movie has not been greenlit yet.',
  'app.alert.season_not_greenlit': 'The season has not been greenlit yet.',
  'app.alert.missing_original_edit.feature.message': 'Failed to create Original Edit',
  'app.alert.missing_original_edit.series.message': 'Failed to create Original Edit for the following episodes:',
  'app.alert.missing_original_edit.cta': 'Submit SIM Ticket',
  'app.alert.missing_original_edit.episode': 'Episode {{episodeNumber}}',

  'app.activity_feed.button.label': 'Activity Feed',
  'app.activity_feed.title': 'Activity Feed',
  'app.activity_feed.change_target.originalEdit': 'Original Edit',
  'app.activity_feed.change_target.additionalEdit': 'Edit ({{territories}})',
  'app.activity_feed.change_target.suppression': 'Suppression ({{territories}})',
  [`app.activity_feed.field.${EditsFieldKey.AlternateEditName}.label`]: 'Alternate Edit Name',
  [`app.activity_feed.field.${EditsFieldKey.EditDeliveryStatus}.label`]: 'Status',
  [`app.activity_feed.field.${EditsFieldKey.Notes}.label`]: 'Notes',
  [`app.activity_feed.field.${EditsFieldKey.FinalEditClass}.label`]: 'Final Edit Classes',
  [`app.activity_feed.field.${EditsFieldKey.MadeForMedia}.label`]: 'Made for Media',
  [`app.activity_feed.field.${EditsFieldKey.Territory}.label`]: 'Territories',
  [`app.activity_feed.field.${EditsFieldKey.RecommendedOutcome}.label`]: 'Status',
  [`app.activity_feed.field.${EditsFieldKey.Treatment}.label`]: 'Treatments',
  [`app.activity_feed.field.${EditsFieldKey.DecisionOwner}.label`]: 'Decision Owner',

  'app.dialog.cancel_title.content': 'The information you entered will be lost.',
  'app.dialog.cancel_title.cancel': 'Stay on page',
  'app.dialog.cancel_title.submit': 'Leave',
  'app.dialog.cancel_title.header': 'Are you sure?',
  'app.dialog.duplicate_territory.content': 'The information you entered will be lost.',
  'app.dialog.duplicate_territory.cancel': 'Cancel',
  'app.dialog.duplicate_territory.submit': 'Confirm',
  'app.dialog.duplicate_territory.header': 'Duplicate Territory',
  'app.dialog.duplicate_territory.dialog_header': 'Are you sure you want to add multiple edits for the same territory?',
  'app.dialog.duplicate_territory.edit': 'Edit',
  'app.dialog.duplicate_territory.suppression': 'Suppression',

  'app.error.not_found.action.label': 'Back To Homepage',

  'app.form.accordion.episode': 'Episode {{episodeNumber}}',
  'app.form.accordion.feature': 'Movie',
  'app.form.accordion.season': 'Season {{seasonNumber}}',
  'app.form.accordion.additional_edits': 'Additional Edits/Suppressions',
  'app.form.button.add_edit.label': 'Add Edit/Suppression',
  'app.form.button.cancel.label': 'Cancel',
  'app.form.button.delete.label': 'Delete',
  'app.form.button.save.label': 'Save',
  'app.form.button.update.label': 'Update',
  'app.form.field.errors.label.fieldErrors': 'You have errors on the form. Check the following fields:',
  'app.form.field.errors.required_field': 'Required Field',
  'app.form.field.errors.header.edit': 'Edit {{editNumber}}',
  [`app.form.field.errors.${EditsFieldKey.DecisionOwner}`]: 'Owner',
  [`app.form.field.errors.${EditsFieldKey.EditDetails}`]: 'Final Edit Class / Treatments',
  [`app.form.field.errors.${EditsFieldKey.Territory}`]: 'Territories',

  [`app.form.edits_table.header.${EditsFieldKey.Episodes}`]: 'Episode',
  [`app.form.edits_table.header.${EditsFieldKey.Territory}`]: 'Territory',
  [`app.form.edits_table.header.${EditsFieldKey.Territory}.editing`]: 'Territories',
  [`app.form.edits_table.header.${EditsFieldKey.EditDeliveryStatus}`]: 'Status',
  [`app.form.edits_table.header.${EditsFieldKey.FinalEditClass}`]: 'Final Edit Class',
  [`app.form.edits_table.header.${EditsFieldKey.FinalEditClass}.editing`]: 'Final Edit Class / Treatments',
  [`app.form.edits_table.header.${EditsFieldKey.Treatment}`]: 'Treatment',
  [`app.form.edits_table.header.${EditsFieldKey.Notes}`]: 'Notes',
  [`app.form.edits_table.header.${EditsFieldKey.DecisionOwner}`]: 'Decision Owner',

  'app.form.field.error.no_data': 'No data',

  [`app.form.field.${EditsFieldKey.AlternateEditName}.label`]: 'Alternate Edit Name',
  [`app.form.field.${EditsFieldKey.DecisionOwner}.label`]: 'Owner *',
  [`app.form.field.${EditsFieldKey.EditDetails}.label`]: 'Final Edit Class / Treatments *',
  [`app.form.field.${EditsFieldKey.EditDetails}.group.${EditDetailGroup.Treatment}`]: 'Treatment',
  [`app.form.field.${EditsFieldKey.EditDeliveryStatus}.label`]: 'Status *',
  [`app.form.field.${EditsFieldKey.EditDeliveryStatus}.group.${EditStatusGroup.EditDeliveryStatus}`]: 'Edit',
  [`app.form.field.${EditsFieldKey.EditDeliveryStatus}.group.${EditStatusGroup.EditOutcome}`]: 'No Edit',
  [`app.form.field.${EditsFieldKey.MadeForMedia}.label`]: 'Made for Media *',
  [`app.form.field.${EditsFieldKey.Notes}.label`]: 'Notes',
  [`app.form.field.${EditsFieldKey.OriginalEditStatus}.label`]: 'Original Edit Status *',
  [`app.form.field.${EditsFieldKey.RecommendedOutcome}.label`]: 'Edit Needed?',
  [`app.form.field.${EditsFieldKey.RecommendedOutcome}.${EditOutcome.EditsNeeded}`]: 'Y',
  [`app.form.field.${EditsFieldKey.RecommendedOutcome}.${EditOutcome.EditsNotNeeded}`]: 'N',
  [`app.form.field.${EditsFieldKey.Territory}.label`]: 'Territories *',
  [`app.form.field.${EditsFieldKey.Territory}.group.${TerritoryGroup.SpecialComplianceTerritories}`]: 'Sp. Compliance Territories',
  [`app.form.field.${EditsFieldKey.Territory}.group.${TerritoryGroup.SpecialRatingsTerritories}`]: 'Sp. Ratings Territories',
  [`app.form.field.${EditsFieldKey.Territory}.group.${TerritoryGroup.Other}`]: 'Other',

  [`app.form.field.${EditsFieldKey.EditDeliveryStatus}.description`]:
    'DMO controlled value for the lifecycle of edited content within Amazon Studios.',
  [`app.form.field.${EditsFieldKey.EditDeliveryStatus}.${EditDeliveryStatus.Cancelled}.description`]:
    'Not a committed value; may add to reflect that assets were intentionally not made for a particular edit - distinct from Suppress.',
  [`app.form.field.${EditsFieldKey.EditDeliveryStatus}.${EditDeliveryStatus.Delivered}.description`]:
    'Title has completed editorial stage, and Final edit(s) is/are available for use, sale, delivery.',
  [`app.form.field.${EditsFieldKey.EditDeliveryStatus}.${EditDeliveryStatus.Pending}.description`]:
    'No edits have been created for this title yet; no assets are available for this title.',
  [`app.form.field.${EditsFieldKey.EditDeliveryStatus}.${EditOutcome.RevertSuppress}.description`]:
    'The decision to suppress the content for a given audience was reversed.',
  [`app.form.field.${EditsFieldKey.EditDeliveryStatus}.${EditOutcome.Suppress}.description`]:
    'The content fails compliance for a given audience and can not be edited in a way that would meet compliance.',

  [`app.form.field.${EditsFieldKey.DecisionOwner}.description`]:
    'DMO controlled value for the Decision Owner of edited content within Amazon Studios.',
  [`app.form.field.${EditsFieldKey.DecisionOwner}.${EditRequestingTeam.Creative}.description`]: 'Studio Creative or Development teams.',
  [`app.form.field.${EditsFieldKey.DecisionOwner}.${EditRequestingTeam.Leadership}.description`]: 'Executive management (L8+).',
  [`app.form.field.${EditsFieldKey.DecisionOwner}.${EditRequestingTeam.Legal}.description`]: 'Legal departments.',
  [`app.form.field.${EditsFieldKey.DecisionOwner}.${EditRequestingTeam.PartnerStudios}.description`]:
    'A co-production studio driving alternate versions, additional edits, etc.',
  [`app.form.field.${EditsFieldKey.DecisionOwner}.${EditRequestingTeam.Programming}.description`]: 'Programming Strategy.',
  [`app.form.field.${EditsFieldKey.DecisionOwner}.${EditRequestingTeam.Talent}.description`]: 'External cast.',
  [`app.form.field.${EditsFieldKey.DecisionOwner}.${EditRequestingTeam.TsCompliance}.description`]: 'VCCs Content Compliance team.',
  [`app.form.field.${EditsFieldKey.DecisionOwner}.${EditRequestingTeam.TsRatings}.description`]: 'VCCs Ratings team.',

  [`app.form.field.${EditsFieldKey.FinalEditClass}.description`]:
    'DMO controlled value and industry-standard term for the reasons a studio might have to produce a separate edit of given content.',
  [`app.form.field.${EditsFieldKey.FinalEditClass}.${FinalEditClass.AlternateEnding}.description`]:
    'A version with an ending that differs from a reference version. (May also be used to describe an Edit that only includes the alternate ending scenes.)',
  [`app.form.field.${EditsFieldKey.FinalEditClass}.${FinalEditClass.Anniversary}.description`]:
    'An anniversary edition. (Often used with Restored.) NOTE: Many Anniversary editions do not differ in any way from the prior release. Instead, they may be part of a bundled media package or are simply a marketing artifact that has been memorialized in existing title management systems. If the difference between an Anniversary release and some prior release cannot be determined, then create a new EDIT ID for the Anniversary release. If they are determined to be the same, then re-use the prior Edit ID.',
  [`app.form.field.${EditsFieldKey.FinalEditClass}.${FinalEditClass.Censored}.description`]:
    ' A version edited for content to comply with local content advisory or censorship requirements. Material is generally removed or substituted (as opposed to sanitized) - *AKA Compliance cuts*',
  [`app.form.field.${EditsFieldKey.FinalEditClass}.${FinalEditClass.Colorized}.description`]:
    'A color version produced from a black-and-white original.',
  [`app.form.field.${EditsFieldKey.FinalEditClass}.${FinalEditClass.ContentBreak}.description`]:
    'A version where the primary program has been modified to accommodate new or different content breaks, such as commercials, pledge breaks, etc. NOTE: If a program is simply interrupted by hard cuts to insert unrelated material (commercials, etc.), that is captured as a Manifestation. This Edit Class only applies when the program itself has been modified – shortened, with video and audio fade out/fade surrounding the break, etc. The break itself and changes to the inserted material do not warrant a new Edit.',
  [`app.form.field.${EditsFieldKey.FinalEditClass}.${FinalEditClass.Credits}.description`]:
    'New or modified main title or end credits, excluding subtitle translations that appear onscreen with the original credits.',
  [`app.form.field.${EditsFieldKey.FinalEditClass}.${FinalEditClass.Dialog}.description`]:
    'A change in dialogue more significant than Sanitized Audio, excluding translation.',
  [`app.form.field.${EditsFieldKey.FinalEditClass}.${FinalEditClass.Directors}.description`]:
    "A version that more closely represents the director's original vision than an earlier reference version. (Usually longer in duration.)",
  [`app.form.field.${EditsFieldKey.FinalEditClass}.${FinalEditClass.DubbingCredits}.description`]:
    'Additional credits added for the dubbing actors, translators, etc.',
  [`app.form.field.${EditsFieldKey.FinalEditClass}.${FinalEditClass.Exit}.description`]:
    'A version that includes exit music (and optional images) that follows the presentation of the work itself and its closing credits. (May also be used to describe a particularly short Edit that only includes the Exit.)',
  [`app.form.field.${EditsFieldKey.FinalEditClass}.${FinalEditClass.Extended}.description`]:
    'Additional material added to the work that makes this version longer than a reference version. Should not be used for changes in playback speed that affect the entire work.',
  [`app.form.field.${EditsFieldKey.FinalEditClass}.${FinalEditClass.Intermission}.description`]:
    ' version that includes intermission music (and optional images) typically presented during a break in a particularly long presentation. (May also be used to describe a particularly short Edit that only includes the Intermission.)',
  [`app.form.field.${EditsFieldKey.FinalEditClass}.${FinalEditClass.Logo}.description`]:
    'A change to the presentation of distributor or producer logos (cards or bumpers) at the head of a work. NOTE: This does not include changes to policy/warning cards or tail-end logos. Those are recorded as a Manifestation.',
  [`app.form.field.${EditsFieldKey.FinalEditClass}.${FinalEditClass.Music}.description`]:
    'A change in the music, generally expressed by a new music cue sheet. (May accompany a territory-specific Edit.)',
  [`app.form.field.${EditsFieldKey.FinalEditClass}.${FinalEditClass.Original}.description`]:
    'The original release version of a work. When this value is used, other classes are usually not present.',
  [`app.form.field.${EditsFieldKey.FinalEditClass}.${FinalEditClass.Overture}.description`]:
    'A version that includes overture music (and optional images) that precedes the presentation of the work itself and its opening credits. (May also be used to describe a particularly short Edit that only includes the Overture.)',
  [`app.form.field.${EditsFieldKey.FinalEditClass}.${FinalEditClass.ProductPlacement}.description`]:
    'Alterations made to a work for the purpose of product placement.',
  [`app.form.field.${EditsFieldKey.FinalEditClass}.${FinalEditClass.Recap}.description`]:
    'A significantly condensed version that provides background important to the narrative thread that continues in a sequel or subsequent episode.',
  [`app.form.field.${EditsFieldKey.FinalEditClass}.${FinalEditClass.Restored}.description`]:
    'A version that more closely resembles the original release than other versions recently available, including restoring missing elements, correcting physical damage, etc. NOTE: Use if both audio and picture were restored at the same time or if it is unknown which elements were restored.',
  [`app.form.field.${EditsFieldKey.FinalEditClass}.${FinalEditClass.Sanitized}.description`]:
    'Objectionable audio and/or images have been obscured rather than removed. NOTE: Use if both audio and picture were sanitized at the same time or if it is unknown which was sanitized.',
  [`app.form.field.${EditsFieldKey.FinalEditClass}.${FinalEditClass.Shortened}.description`]:
    'Materials removed for other than censorship reasons, making this version shorter than a reference version. Used to fit into a shorter broadcast time slot or to allow more commercials. Should not be used for changes in playback speed that affect the entire work.',
  [`app.form.field.${EditsFieldKey.FinalEditClass}.${FinalEditClass.SoundEffects}.description`]:
    'A change in the audio, excluding music and dialogue.',
  [`app.form.field.${EditsFieldKey.FinalEditClass}.${FinalEditClass.Syndication}.description`]:
    'A version produced expressly for television syndication. May also occur with Shortened, Sanitized, Censored, etc.',
  [`app.form.field.${EditsFieldKey.FinalEditClass}.${FinalEditClass.Unrated}.description`]:
    'An un-cut version; one that has not received content advisory or censorship classification.',

  'app.form.loading': 'Loading',
  'app.form.processing': 'Processing',
  'app.form.update.success.alert.title': 'Success!',
  'app.form.update.success.alert.description': 'Title has been updated.',
  'app.form.update.error.alert.title': 'Error',
  'app.form.update.error.alert.description': 'An unexpected error has ocurred. Please try again.',

  'app.form.placeholder.label': 'Please select a title first.',
  'app.form.placeholder.episode.label': 'There is no episode information.<br />Something missing?',
  'app.form.placeholder.season.label': 'There is no Sub Content for this title.<br />Something missing?',
  'app.form.placeholder.button.request_update.label': 'Request Update',

  'app.form.title_metadata.distribution_services': 'Distribution Services',
  'app.form.title_metadata.originating_team': 'Originating Team',
  'app.form.title_metadata.originating_territory': 'Originating Territory',
  'app.form.title_metadata.original_language': 'Original Language',
  'app.form.title_metadata.show_code': 'Show Code',
  'app.form.title_metadata.show_code.placeholder': 'N/A',
  'app.form.title_metadata.studios_apps_notes': 'StudioApps Notes',
  'app.form.title_metadata.suppression_note': 'Suppression',
  'app.form.title_metadata.edit_note': 'Edits',

  'app.page.main.side.title': 'Edits and Suppressions',
  'app.page.main.primary.title': 'Select a Title',

  'app.coming_soon.series_titles.description': 'Series title support is in development!',
  'app.loading': 'Loading Origin Edit',
  'app.product_name': 'Origin Edit',

  'app.universal_header.product_identifier': 'Origin Author',
  'app.universal_header.link.author': 'Titles',
  'app.universal_header.link.edits': 'Edits and Suppressions',
};
