import { AccessDeniedError, AppAlert } from '@amzn/sitc-frontend/components';
import { useUniversalHeader } from '@amzn/sitc-frontend/hooks';
import { Radio } from '@amzn/sitc-frontend-micro-app-sdk-core/radio';
import { Action } from '@amzn/studios-origin-edit-app-auth/constants';
import Grid from '@mui/material/Grid';
import React from 'react';

import { PermissionGate } from '../components/common/PermissionGate';
import { RevisionHistoryContainer } from '../components/RevisionHistory/RevisionHistoryContainer';
import { microAppName } from '../configs/app';
import { ApolloProvider } from '../context/Apollo';
import { NavigationProvider } from '../context/Navigation';
import { NavigationParamsProvider } from '../context/NavigationParams';
import { RevisionHistoryProvider } from '../context/RevisionHistory';
import { useMicroAppInitialization } from '../hooks/micro-app-initialization';
import { useUniversalHeaderConfig } from '../hooks/universal-header';

interface Props {
  children?: React.ReactNode;
}

export const Base: React.FC<Props> = ({ children }) => {
  const { radio } = useMicroAppInitialization();

  useUniversalHeader({ radio: radio as unknown as Radio, microAppName, universalHeaderConfig: useUniversalHeaderConfig() });

  return (
    <PermissionGate action={Action.Read} unauthorizedPlaceholder={<AccessDeniedError />}>
      <NavigationParamsProvider>
        <NavigationProvider>
          <ApolloProvider>
            <RevisionHistoryProvider>
              <Grid container sx={{ paddingTop: '80px' }}>
                <Grid item xs={12} sx={{ padding: '2rem' }}>
                  {children}
                  <AppAlert />
                  <RevisionHistoryContainer />
                </Grid>
              </Grid>
            </RevisionHistoryProvider>
          </ApolloProvider>
        </NavigationProvider>
      </NavigationParamsProvider>
    </PermissionGate>
  );
};
