import { Action } from '@amzn/studios-origin-edit-app-auth/constants';
import React from 'react';

import { usePermission } from '../../context/Authorization';

interface Props {
  action: Action;
  unauthorizedPlaceholder?: React.ReactNode;
  children: React.ReactNode;
}

export const PermissionGate: React.FC<Props> = ({ action, children, unauthorizedPlaceholder = 'Unauthorized' }) => {
  const hasPermission = usePermission(action);

  if (!hasPermission) {
    return unauthorizedPlaceholder as React.ReactElement;
  }

  return children as React.ReactElement;
};
