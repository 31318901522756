import '../i18n';

import { PageLoading } from '@amzn/sitc-frontend/components';
import {
  AlertContextProvider,
  AppAlertBannerContextProvider,
  AuthSessionProvider,
  FeatureFlagsProvider,
  MicroAppConfigProvider,
} from '@amzn/sitc-frontend/contexts';
import { MicroAppInitializationProps } from '@amzn/sitc-frontend/types';
import i18n from 'i18next';
import React, { Suspense } from 'react';
import { I18nextProvider } from 'react-i18next';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import { microAppName } from '../configs/app';
import { baseRoute, updateRoute, viewRoute } from '../configs/navigation';
import { AuthorizationProvider } from '../context/Authorization';
import { FormModeProvider } from '../context/FormMode';
import { MicroAppInitializationProvider } from '../context/MicroAppInitialization';
import { ThemeProvider } from '../context/Theme';
import { ThemeModeProvider } from '../context/ThemeMode';
import { useAppTitle } from '../hooks/app-title';
import { Base } from '../pages/Base';
import { NotFound } from '../pages/error/NotFound';
import { appDomainTemplate, MicroAppConfigContext, MicroAppConfigResultType } from '../utils/app';
import { logInfo } from '../utils/log';
import { AppLoading } from './common/AppLoading';

const Index = React.lazy(() => import('../pages/Index'));

const Edits = React.lazy(() => import('../pages/Edits'));

const StudiosOriginEditApp: React.FC<MicroAppInitializationProps> = (props) => {
  const {
    appId,
    authSession,
    basePath,
    featureOverrides,
    radio,
    stage,
    userPreferences: {
      preferences: { theme },
    },
  } = props;
  logInfo('App info:', props);

  useAppTitle(radio.getChannel('app'));

  return (
    <MicroAppConfigProvider<MicroAppConfigResultType>
      appDomainTemplate={appDomainTemplate}
      appId={appId}
      appLoader={<AppLoading />}
      appName={microAppName}
      context={MicroAppConfigContext}
      stage={stage}>
      <MicroAppInitializationProvider {...props}>
        <ThemeModeProvider themePreference={theme}>
          <ThemeProvider>
            <I18nextProvider i18n={i18n}>
              <BrowserRouter basename={basePath}>
                <AlertContextProvider closeOnLocationChange={false}>
                  <AppAlertBannerContextProvider closeOnLocationChange>
                    <AuthSessionProvider authSession={authSession}>
                      <AuthorizationProvider stage={stage}>
                        <FeatureFlagsProvider featureOverrides={featureOverrides}>
                          <FormModeProvider>
                            <Suspense fallback={<PageLoading />}>
                              <Routes>
                                <Route
                                  path="/"
                                  element={
                                    <Base>
                                      <Index />
                                    </Base>
                                  }
                                />
                                <Route path={`/${baseRoute}`} element={<Edits />} />
                                <Route path={`/${baseRoute}/:titleDocumentId/${viewRoute}`} element={<Edits />} />
                                <Route path={`/${baseRoute}/:titleDocumentId/${updateRoute}`} element={<Edits />} />
                                <Route
                                  path={`/${baseRoute}/:titleDocumentId/seasons/:seasonDocumentId/${updateRoute}`}
                                  element={<Edits />}
                                />
                                <Route path="*" element={<NotFound />} />
                              </Routes>
                            </Suspense>
                          </FormModeProvider>
                        </FeatureFlagsProvider>
                      </AuthorizationProvider>
                    </AuthSessionProvider>
                  </AppAlertBannerContextProvider>
                </AlertContextProvider>
              </BrowserRouter>
            </I18nextProvider>
          </ThemeProvider>
        </ThemeModeProvider>
      </MicroAppInitializationProvider>
    </MicroAppConfigProvider>
  );
};

export default StudiosOriginEditApp;
