import { ChangeTargetBaseProps } from '@amzn/sitc-frontend-types/revision-history';
import React from 'react';

export const EditTarget: React.FC<ChangeTargetBaseProps> = ({ description, revision }) => {
  const { isdgVertexFilterKeys } = revision;

  const prefix = `${isdgVertexFilterKeys?.[0]?.split(':')[0] || ''} - ` || '';

  return (
    <strong>
      {prefix}
      {description?.attribute?.label || null}
    </strong>
  );
};
