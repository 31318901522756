import { NotFoundError } from '@amzn/sitc-frontend/components';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useEditsNavigation } from '../../hooks/navigation';

export const NotFound: React.FC = () => {
  const { t } = useTranslation();
  const { navigateToRoot } = useEditsNavigation();

  const onAction = () => {
    navigateToRoot();
  };

  return <NotFoundError actionLabel={t('app.error.not_found.action.label')} onAction={onAction} />;
};
