import { UniversalHeaderConfig } from '@amzn/sitc-frontend/hooks';
import { HeaderLink } from '@amzn/sitc-frontend/types';
import { Action } from '@amzn/studios-origin-edit-app-auth/constants';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { usePermission } from '../context/Authorization';

export const useUniversalHeaderConfig = (): UniversalHeaderConfig => {
  const { t } = useTranslation();
  const hasEditReadPermission = usePermission(Action.Read);
  const hasAuthorNavigationPermission = usePermission(Action.NavigateToAuthor);

  const headerLinks: HeaderLink[] = useMemo(() => {
    const links: HeaderLink[] = [];

    if (hasAuthorNavigationPermission) {
      links.push({
        primary: t('app.universal_header.link.author'),
        path: '/origin-author',
        reloadOnNav: true,
      });
    }

    if (hasEditReadPermission) {
      links.push({
        primary: t('app.universal_header.link.edits'),
        path: '/',
        reloadOnNav: false,
      });
    }

    return links;
  }, [hasEditReadPermission, hasAuthorNavigationPermission]);

  return useMemo(
    () => ({
      productIdentifier: t('app.universal_header.product_identifier'),
      headerLinks,
    }),
    [headerLinks]
  );
};
