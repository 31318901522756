import { backlotDarkThemeExperimental, backlotLightTheme } from '@amzn/sitc-frontend-theme';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import React, { useContext } from 'react';

import { ThemeModeContext } from './ThemeMode';

interface ThemeProviderProps {
  children: React.ReactNode;
}

export const ThemeProvider: React.FC<ThemeProviderProps> = ({ children }) => {
  const { themeMode } = useContext(ThemeModeContext);
  const theme = themeMode === 'light' ? backlotLightTheme : backlotDarkThemeExperimental;

  document.body.style.backgroundColor = theme.palette.background.default;

  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </MuiThemeProvider>
  );
};
