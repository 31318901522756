import { useMicroApp } from '@amzn/sitc-frontend/hooks';
import { Channel } from '@amzn/sitc-frontend-micro-app-sdk-core/radio';
import { useTheme } from '@mui/material/styles';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { SingleSpaContext } from 'single-spa-react';

import { useMicroAppInitialization } from '../../hooks/micro-app-initialization';
import { useRevisionHistory } from '../../hooks/revision-history';
import { EventNames, RevisionHistoryRegisteredEventPayload } from '../../types/revision-history';
import { getIsdgAttributes } from '../../utils/revision-history/attributes';
import { channelName, createRevisionHistoryTheme, getRegistrationKey } from '../../utils/revision-history/config';

const EventSource = 'StudiosOriginEditApp';

export const RevisionHistoryContainer: React.FC = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const microAppInitializationProps = useMicroAppInitialization();
  const { setRevisionHistoryReady, setRevisionHistoryRegistrationErrors } = useRevisionHistory();

  const { appId, radio } = microAppInitializationProps;
  const channel = radio.getChannel(channelName) as unknown as Channel;
  const registrationKey = getRegistrationKey(appId);

  useMicroApp({
    loadProps: {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      microAppContainer: () => document.querySelector('.studios-origin-edit-app-revision-history-container')!,
      microAppInitializationProps: { ...microAppInitializationProps, registrationKey },
      microAppName: 'amzn-studios-revision-history-app',
      singleSpaContext: SingleSpaContext,
    },
    registrationProps: {
      channel,
      registrationKey,
      registrationOptions: {
        attributes: {},
        isdgAttributes: getIsdgAttributes(t),
        layout: 'drawer',
        maxUncollapsibleCount: 5,
        theme: createRevisionHistoryTheme(theme),
        title: t('app.activity_feed.title'),
      },
    },
  });

  useEffect(() => {
    const appRegisteredSubscription = channel?.subscribe<RevisionHistoryRegisteredEventPayload>(
      EventNames.Registered,
      (event) => {
        const eventRegistrationKey = event.payload?.registrationKey;

        if (eventRegistrationKey !== registrationKey) {
          return;
        }

        setRevisionHistoryReady(!!event.payload?.success);
        setRevisionHistoryRegistrationErrors(event.payload?.errors || []);

        appRegisteredSubscription?.unsubscribe();
      },
      { replay: true, source: EventSource }
    );

    const unsubscribeAppRegistration = () => {
      appRegisteredSubscription?.unsubscribe();
    };

    return unsubscribeAppRegistration;
  }, [channel, registrationKey]);

  return <section className="studios-origin-edit-app-revision-history-container" />;
};
