import { Theme } from '@mui/material/styles';

export const channelName = 'revision-history';

export const pageSize = 5;

export const getRegistrationKey = (appId: string) => `${appId}#${channelName}`;

export const createRevisionHistoryTheme = (theme: Theme): Theme => ({
  ...theme,
  components: {
    ...(theme.components ?? {}),
    MuiDrawer: {
      styleOverrides: {
        root: {
          zIndex: 1400,
          top: 0,
        },
        paper: {
          top: 0,
          height: '100vh',
          zIndex: 1400,
        },
      },
    },
  },
});
