import { BasicType } from '@amzn/sitc-frontend-types/stap/types';
import React from 'react';

interface Props<T extends string> {
  value?: T | T[];
  types: Record<T, BasicType<T>>;
}

const getLabel = <T extends string>(types: Record<T, BasicType<T>>, value?: T): string => (value ? types[value]?.label || '' : '');

export const BasicTypeRevision: React.FC<Props<string>> = ({ value, types }) => (
  <span>{Array.isArray(value) ? value.map((val: string) => getLabel(types, val)).join(', ') : getLabel(types, value)}</span>
);
