import { useUserGroups } from '@amzn/sitc-frontend/contexts';
import { Action } from '@amzn/studios-origin-edit-app-auth/constants';
import { OriginEditAppUserAuth } from '@amzn/studios-origin-edit-app-auth/user-auth';
import React, { useContext, useMemo } from 'react';

interface AuthProviderProps {
  stage: string;
  children: React.ReactNode;
}

export const AuthContext = React.createContext<{
  userAuth?: OriginEditAppUserAuth;
}>({
  userAuth: undefined,
});

export const AuthorizationProvider: React.FC<AuthProviderProps> = ({ children, stage }) => {
  const userGroups = useUserGroups();
  const contextValue = useMemo(() => {
    const userAuth = new OriginEditAppUserAuth({ customGroups: userGroups }, { stage });

    return { userAuth };
  }, [stage, userGroups]);

  return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>;
};

export const usePermission = (action: Action): boolean => {
  const { userAuth } = useContext(AuthContext);

  if (!userAuth) {
    return false;
  }

  return userAuth.evaluatePermission(action);
};
