import { FinalEditClassTypes } from '@amzn/sitc-frontend-types/stap/final-edit-class';
import { TreatmentTypes } from '@amzn/sitc-frontend-types/stap/treatment';
import { BasicType } from '@amzn/sitc-frontend-types/stap/types';
import {
  FinalEditClass as FinalEditClassValue,
  Treatment as TreatmentValue,
} from '@amzn/studios-domain-authority-java-script-clients/types/stap';
import _omit from 'lodash/omit';

export enum EditDetailGroup {
  Treatment = 'Treatment',
  FinalEditClass = 'FinalEditClass',
}

export interface EditDetailOption {
  group: EditDetailGroup;
  label: string;
  value: FinalEditClassValue | TreatmentValue;
}

export const censoredEditDetailOption: EditDetailOption = {
  label: FinalEditClassTypes[FinalEditClassValue.Censored].label,
  value: FinalEditClassValue.Censored,
  group: EditDetailGroup.FinalEditClass,
};

export const editDetailOptions: EditDetailOption[] = [
  ...Object.values(_omit(TreatmentTypes, TreatmentValue.Invalid)).map(({ label, value }: BasicType<TreatmentValue>) => ({
    group: EditDetailGroup.Treatment,
    label,
    value,
  })),
  ...Object.values(_omit(FinalEditClassTypes, FinalEditClassValue.Invalid)).map(({ label, value }: BasicType<FinalEditClassValue>) => ({
    group: EditDetailGroup.FinalEditClass,
    label,
    value,
  })),
];
