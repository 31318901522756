import { useContext } from 'react';

import { NavigationContext, NavigationContextState } from '../context/Navigation';

export const useEditsNavigation = (): NavigationContextState => {
  const editsNavigation = useContext(NavigationContext);

  if (!editsNavigation) {
    throw new Error('Missing NavigationProvider');
  }

  return editsNavigation;
};
